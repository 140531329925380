import { useEffect, useState } from "react";
import { getPresalePaymentMethods } from "../utils/api";

export interface IPaymentMethod {
  network: string,
  currency: string,
  // address: string,
}

export const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await getPresalePaymentMethods();
        setPaymentMethods(response.data.methods);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch payment methods');
        setLoading(false);
      }
    };

    fetchPaymentMethods();
  }, []);

  return {
    paymentMethods: paymentMethods || null,
    loading,
    error,
  };
};

export const getUniquePaymentMethods = (paymentMethods: IPaymentMethod[] | null): IPaymentMethod[] | null => {
  if (!paymentMethods) {
    return null;
  }

  return paymentMethods.reduce((acc, current) => {
    if (!acc.some(item => item.network === current.network)) {
      acc.push(current);
    }
    return acc;
  }, [] as IPaymentMethod[]);
};
