import React, { useEffect, useState } from "react";

interface ProgressBarProps {
  progress: number;
  totalSquares?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, totalSquares = 100 }) => {
  const total = 1_000_000;
  const filledSquares = Math.round((progress / total) * totalSquares);
  const [animatedSquares, setAnimatedSquares] = useState(0);

  useEffect(() => {
    let currentSquare = 0;
    setAnimatedSquares(0);

    const interval = setInterval(() => {
      if (currentSquare < filledSquares) {
        setAnimatedSquares(currentSquare + 1);
        currentSquare++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [progress, filledSquares]);
  const squaresArray = Array.from({ length: totalSquares }, (_, index) => index < animatedSquares);

  return (
    <div style={styles.progressBar}>
      {squaresArray.map((filled, index) => (
        <div
          key={index}
          style={{
            ...styles.square,
            backgroundColor: filled ? '#9BDDFF' : '#D9E3F0',
            animation: filled ? 'fillStep 0.5s ease forwards' : 'none',
            animationDelay: `${index * 0.1}s`,
          }}
        />
      ))}
    </div>
  );
};

const styles = {
  progressBar: {
    display: 'flex',
    gap: '2px',
    height: '25px',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '10px',
    padding: '5px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  square: {
    width: '15px',
    height: '15px',
    border: '1px solid #FFFFFF',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};

export default ProgressBar;
