import { ReactComponent as SolanaIcon } from 'assets/svg/solana.svg';
import { ReactComponent as ArbitrumIcon } from 'assets/svg/arbitrum.svg';
import { ReactComponent as EthereumIcon } from 'assets/svg/ethereum.svg';
import { ReactComponent as TronIcon } from 'assets/svg/tron.svg';
import { ReactComponent as TonIcon } from 'assets/svg/ton.svg';
import { ReactComponent as BnbIcon } from 'assets/svg/bnb.svg';
import { ReactComponent as UsdtIcon } from 'assets/svg/usdt.svg';
import { ReactComponent as UsdcIcon } from 'assets/svg/usdc.svg';

export const getCurrencyIcon = (symbol: string) => {
  switch (symbol) {
    case 'eth': return EthereumIcon;
    case 'sol': return SolanaIcon;
    case 'trx': return TronIcon;
    case 'ton': return TonIcon;
    case 'bnb': return BnbIcon;
    case 'arb': return ArbitrumIcon;
    case 'usdt': return UsdtIcon;
    case 'usdc': return UsdcIcon;
  }
  return null;
}

// export const getNativeCurrencyIcon = (network: string) => {
//   switch (network) {
//     case 'ethereum': return getCurrencyIcon('eth');
//     case 'solana': return getCurrencyIcon('sol');
//     case 'tron': return getCurrencyIcon('tron');
//     case 'ton': return getCurrencyIcon('ton');
//     case 'bnb': return getCurrencyIcon('bnb');
//     case 'arbitrum': return getCurrencyIcon('arb');
//   }
//   return null;
// }

const nativeCurrencies = {
  ethereum: 'ETH',
  solana: 'SOL',
  tron: 'TRX',
  ton: 'TON',
  bnb: 'BNB',
  bsc: 'BNB',
  arbitrum: 'ARB',
}

export function isNativeNetworkCurrency(network: string, symbol: string) {
  // @ts-expect-error please fix
  return nativeCurrencies[network.toLowerCase()] === symbol.toUpperCase();
}

export function getNativeCurrencySymbol(network: string) {
  // @ts-expect-error please fix
  return nativeCurrencies[network.toLowerCase()] || null;
}
