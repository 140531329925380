import axios from 'axios';

export const API_PRESALE_STATUS = '/presale/status';
export const API_PRESALE_PAYMENT_METHODS = '/presale/payment-methods';
export const API_PRESALE_EXCHANGE_RATE = '/presale/exchange-rate';
export const API_GET_DEPOSIT_ADDRESS = '/presale/get-deposit-address';
export const API_GET_REFERRAL_LINK = '/referral/link';
export const API_GET_BALANCE = '/presale/get-balance';

// TODO replace http to https
const BACKEND_URL = 'http://api.paymentmodule.cloud';

const buildUrl = (endpoint: string) => `${BACKEND_URL}${endpoint}`;

export interface IPresaleStatus {
  currentPrice: number,
  finalPrice: number,
  usdRaised: number,
  goal: number,
  symbol: string,
}

export async function getPresaleStatus() {
  const url = buildUrl(API_PRESALE_STATUS);
  return axios.get<IPresaleStatus>(url);
}

export async function getPresalePaymentMethods() {
  const url = buildUrl(API_PRESALE_PAYMENT_METHODS);
  return axios.get(url);
}

export interface IExchangeRate {
  currency: string,
  rate: string,
}

interface IPresaleExchangeRatesResponse {
  rates: Array<IExchangeRate>,
}

export async function getPresaleExchangeRates() {
  const url = buildUrl(API_PRESALE_EXCHANGE_RATE);
  return axios.get<IPresaleExchangeRatesResponse>(url);
}

interface IPresaleDepositAddressResponse {
  currency: string
  depositAddress: string
  ownerAddress: string
}

export async function getPresaleDepositAddress(currency: string, network: string, ownerAddress: string) {
  const url = buildUrl(API_GET_DEPOSIT_ADDRESS);

  const data = {
    currency,
    network,
    ownerAddress,
  }

  return axios.post<IPresaleDepositAddressResponse>(url, data);
}

interface IReferralLinkResponse {
  referralLink: {
    ownerAddress: string
    link: string
  }
}

export async function getReferralLink(walletAddress: string) {
  const url = buildUrl(API_GET_REFERRAL_LINK);

  return axios.get<IReferralLinkResponse>(url, {
    params: {
      walletAddress,
    },
  });
}

export async function getTokenBalance(network: string | null, ownerAddress: string | null) {
  if (!network || !ownerAddress) {
    return null;
  }

  const url = buildUrl(API_GET_BALANCE);

  const data = {
    network,
    ownerAddress,
  }

  return axios.post(url, data);
}
