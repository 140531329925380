import { useState, useEffect } from 'react';
import { getPresaleStatus, IPresaleStatus } from "../utils/api";

export const usePresaleStatus = () => {
  const [status, setStatus] = useState<IPresaleStatus>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPresaleStatus = async () => {
      try {
        const response = await getPresaleStatus();

        setStatus(response.data);
      } catch (err) {
        setError('Failed to fetch payment methods');
      } finally {
        setLoading(false);
      }
    };

    fetchPresaleStatus();
  }, []);

  return {
    status: status || null,
    loading,
    error,
  };
};
