import { FC, useCallback, useEffect, useMemo } from "react";

import { Typography } from "antd";
import { IPaymentMethod } from "../../../hooks/usePaymentMethods";
import { getCurrencyIcon, getNativeCurrencySymbol } from "../../../utils/blockchain";

const { Text } = Typography;

const styles = {
  iconContainer: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "50%",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  },
  iconRow: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "20px",
    marginLeft: "10px",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  selected: {
    border: "2px solid #1890ff",
  },
} as const;

interface IPayWith {
  paymentMethods: IPaymentMethod[]
  selectedPaymentMethod: IPaymentMethod
  selectedCurrency: string
  setSelectedCurrency: (symbol: string) => void
}

const PayWith: FC<IPayWith> = ({
  paymentMethods,
  selectedPaymentMethod,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  useEffect(() => {
    setSelectedCurrency(selectedPaymentMethod.currency)
  }, [selectedPaymentMethod]);

  const PaymentCurrencyIcon: FC<{ currency: string }> = ({ currency }) => {
    const SvgIcon = getCurrencyIcon(currency.toLowerCase());
    if (!SvgIcon) {
      return null;
    }

    return (
      <SvgIcon
        style={styles.icon}
        title={currency}
      />
    );
  };

  const RoundedCryptoButton: FC<{ key: string, currency: string }> = useCallback(({ currency }) => (
    <div
      style={{
        ...styles.iconContainer,
        ...(selectedCurrency === currency ? styles.selected : {})
      }}
      onClick={() => setSelectedCurrency(currency)}
    >
      <PaymentCurrencyIcon
        currency={currency}
      />
    </div>
  ), [selectedCurrency]);

  const availablePaymentMethods = useMemo((): Array<IPaymentMethod> => {
    const possiblePaymentSymbols = [
      getNativeCurrencySymbol(selectedPaymentMethod.network), // required
      'USDT', // optional
      'USDC', // optional
    ];

    return possiblePaymentSymbols
      .map(symbol => paymentMethods.find(pm => pm.network === selectedPaymentMethod.network && pm.currency === symbol))
      .filter((pm): pm is IPaymentMethod => Boolean(pm));
  }, [selectedPaymentMethod]);

  if (!selectedCurrency || !availablePaymentMethods) {
    return null;
  }

  return (
    <div>
      <Text style={{
        display: "flex",
        fontWeight: 600,
        marginLeft: "15px",
        marginBottom: "10px",
        justifyContent: "left",
        alignItems: "center"
      }}>{`Pay with ${selectedCurrency}`}</Text>
      <div style={styles.iconRow}>
        {availablePaymentMethods.map(paymentMethod => (
          <RoundedCryptoButton
            key={paymentMethod.currency}
            currency={paymentMethod.currency}
          />
        ))}
      </div>
    </div>
  );
};

export default PayWith;
