import { useEffect, useState } from "react";
import { getPresaleExchangeRates, IExchangeRate } from "../utils/api";

export const useExchangeRates = () => {
  const [rates, setRates] = useState<IExchangeRate[]>([]);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await getPresaleExchangeRates();
        setRates(response.data.rates);
      } catch (err) {
        console.error(err);
      }
    };

    fetchRates();
  }, []);

  return rates;
};
