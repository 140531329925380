import { useCallback, useState } from "react";

import { getTokenBalance } from "../utils/api";
import { usePolling } from "./usePolling";

export const useBalance = (network: string | null, ownerAddress: string | null) => {
  const [balance, setBalance] = useState<string | null>(null);

  const fetchBalance = useCallback(async () => {
    try {
      const response = await getTokenBalance(network, ownerAddress);
      setBalance(response?.data || null);
    } catch (err) {
      console.log('Error fetching token balance:');
      console.error(err);
      throw err;
    }
  }, [network, ownerAddress]);

  usePolling(fetchBalance, 10000);

  return balance;
};
