import { useCallback, useState } from "react";

import { IPaymentMethod } from "./usePaymentMethods";
import { useWriteContract } from "./useWriteContract";
import { isNativeNetworkCurrency } from "../utils/blockchain";
import { getEllipsisTxt } from "../utils/formatters";

export const useTransfer = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const { transferNative } = useWriteContract();

  const makeTransaction = useCallback(async (
    selectedPaymentMethod: IPaymentMethod,
    productTokensAmount: number,
    depositAddress: string,
  ) => {
    if (!selectedPaymentMethod || !productTokensAmount || !depositAddress) {
      console.error('Bad inputs');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const isNative = isNativeNetworkCurrency(selectedPaymentMethod.network, selectedPaymentMethod.currency);

      console.log(`Selected Payment Method: ${selectedPaymentMethod.network}/${selectedPaymentMethod.currency}, is native currency? - ${isNative}`);

      if (isNative) {
        console.log(`Transfer native currency ${selectedPaymentMethod.currency}`);

        const { success, data } = await transferNative(depositAddress, productTokensAmount);

        if (success) {
          console.log(`Success! Transaction Hash: ${getEllipsisTxt(data?.transactionHash ?? "Transactions Hash missing.", 8)}`);
        } else {
          setError(`An error occurred: ${data}`);
        }
      } else {
        console.log('Send payment token from user account (NOT IMPLEMENTED YET)'); // TODO implement transfer USDT/USDC
      }
    } catch (e) {
      setError('Error sending transaction');
    } finally {
      setLoading(false);
    }
  }, [transferNative]);

  return {
    makeTransaction,
    isLoading,
    transferError: error,
  };
};
