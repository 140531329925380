import { CSSProperties, FC, ReactElement, useMemo } from "react";

import { Row, Col } from "antd";
import { Flex, Typography } from "antd";
import { Button } from "antd";

import ARB from "assets/svg/cryptoCurrencies/ARB.svg"
import BNB from "assets/svg/cryptoCurrencies/BNB.svg"
import ETH from "assets/svg/cryptoCurrencies/ETH.svg"
import SOL from "assets/svg/cryptoCurrencies/SOL.svg"
import TON from "assets/svg/cryptoCurrencies/TON.svg"
import TRX from "assets/svg/cryptoCurrencies/TRX.svg"

import { IPaymentMethod } from "../../../hooks/usePaymentMethods";

const { Title, Text } = Typography;

const styles = {
  buttonSign: {
    margin: "15px auto"
  },
  paymentMethods: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as CSSProperties["flexWrap"]
  },
  paymentButton: {
    transition: "all 0.3s",
    flex: "1 1 30%",
    maxWidth: "115px",
    cursor: "pointer",
    width: "115px",
    height: "51px",
    borderRadius: "10px",
    background: "#FFFFFF",
    border: "2px solid #D1D5DB",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    textAlign: "center" as CSSProperties["textAlign"],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 500,
    padding: "0 8px"
  },
  greyBold: {
    fontWeight: "700",
    color: "rgba(106, 106, 106, 1)",
    letterSpacing: "-1px"
  },
  selected: {
    boxShadow: "0px 4px 0px 0px rgba(155, 221, 255, 1)"
  },
  paymentCurrency: {
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "16.5px",
    letterSpacing: "-0.02em",
    textAlign: "left"
  },
  paymentNetwork: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "13.2px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    color: "rgba(106, 106, 106, 1)"
  }
} as const;

interface IPaymentMethods {
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod;
  setSelectedPaymentMethod: (pm: IPaymentMethod) => void;
}

const imageMapping: Record<string, string> = {
  ARB: ARB,
  BNB: BNB,
  ETH: ETH,
  SOL: SOL,
  TON: TON,
  TRX: TRX
};

const PaymentMethods: FC<IPaymentMethods> = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod
}): ReactElement => {
  const selectedNetwork = useMemo(() => selectedPaymentMethod.network, [selectedPaymentMethod]);

  return (
    <Flex vertical justify={"center"} align={"center"}>
      <Title style={styles.greyBold} level={3}>
        Presale payment methods
      </Title>
      <Row gutter={[10, 13]} justify={"center"}>
        {paymentMethods.map((paymentMethod) => (
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              style={{
                ...styles.paymentButton,
                ...(selectedNetwork === paymentMethod.network ? styles.selected : {})
              }}
              key={paymentMethod.network}
              onClick={() => setSelectedPaymentMethod(paymentMethod)}
              iconPosition={'start'}
            >
              <img src={imageMapping[paymentMethod.currency] || ""} alt="" />
              <Flex vertical justify={"space-between"} align={"flex-start"}>
                <Text style={styles.paymentCurrency}>{paymentMethod.currency}</Text>
                <Text style={styles.paymentNetwork}>{paymentMethod.network}</Text>
              </Flex>
            </Button>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default PaymentMethods;
