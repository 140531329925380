import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { Typography, Divider, Button, Input } from "antd";

import { useWindowSize } from "hooks";
import { Flex } from "antd";

const { Title, Text } = Typography;
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

import { getEllipsisTxt } from "../../utils/formatters";
import {
  getUniquePaymentMethods,
  IPaymentMethod,
  usePaymentMethods
} from "../../hooks/usePaymentMethods";
import { usePresaleStatus } from "../../hooks/usePresaleStatus";
import { getPresaleDepositAddress, getReferralLink } from "../../utils/api";
import { useExchangeRates } from "../../hooks/useExchangeRates";
import { useBalance } from "../../hooks/useBalance";
import { copyToClipboard } from "../../utils/common";
import PaymentMethods from "./components/PaymentMethods";
import PayWith from "./components/PayWith";
import { useTransfer } from "../../hooks/useTransfer";
import ProgressBar from "./components/ProgressBar";

const styles = {
  container: {
    width: "100%",
    minWidth: "428px",
    maxWidth: "600px",
    textAlign: "center" as CSSProperties["textAlign"],
    margin: "auto",
    padding: "30px 20px",
    borderRadius: "15px",
    boxShadow: "0px 0px 15px 15px rgba(0, 0, 0, 0.05)",
    background: "linear-gradient(to bottom,rgb(250, 245, 239) 42%, rgb(247, 135, 129))"
  },
  priceInfoWrapper: {
    marginBottom: "17px",
  },
  priceTitle: {
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(106, 106, 106, 1)",
  },
  priceInfoPrice: {
    color: '#000000',
    fontWeight: 700,
    fontSize: "16px",
  },
  priceInfo: {
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
  },
  progressBar: {
    background: 'rgb(224, 224, 224)',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  progress: {
    width: "30%",
    height: "100%",
    backgroundColor: "#00bcd4",
  },
  balance: {
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
  },
  actionButton: {
    width: "100%",
    margin: "auto",
    marginTop: "20px",
    background: "#000",
    color: "#fff",
    padding: "15px 0",
    borderRadius: "10px",
    fontSize: "18px",
    fontWeight: 600,
  },
  refButton: {
    marginTop: "20px",
    color: "#000000",
    padding: "15px",
    borderRadius: "10px",
  },
  infoText: {
    color: "#666",
    fontSize: "12px",
    margin: "10px 0",
  },
  warningText: {
    color: "#FF6666",
    fontSize: "12px",
    fontWeight: 600,
  },
  footerText: {
    fontSize: "14px",
    color: "#999",
    marginTop: "15px",
    fontWeight: 500,
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as CSSProperties["flexDirection"],
    margin: "10px",
    paddingRight: "20px",
    gap: "20px",
  },
  inputField: {
    width: "100%",
    marginBottom: "10px",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  displayPaneTitleSymbol: {
    fontWeight: "bold",
    letterSpacing: "-2px",
    fontSize: "30px",
    lineHeight: "35.2px",
  },
  displayPaneTitleLive: {
    color: "rgba(248, 135, 129, 1)",
    letterSpacing: "-2px"
  }
};

type DisplayPaneProps = {
  isDarkMode: boolean;
};

const DisplayPane: React.FC<DisplayPaneProps> = ({ isDarkMode }) => {
  const { isActive, account } = useWeb3React();
  const { isTablet } = useWindowSize();
  const { status } = usePresaleStatus();
  const { paymentMethods } = usePaymentMethods();
  const rates = useExchangeRates();

  const getExchangeRate = useCallback((currency: string) => {
    const rateObj = rates.find(rate => rate.currency.toLowerCase() === currency.toLowerCase());
    return rateObj ? Number(rateObj.rate) : null;
  }, [rates]);

  const uniquePaymentMethods = useMemo(() => getUniquePaymentMethods(paymentMethods), [paymentMethods]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | null>(null);

  useEffect(() => {
    setSelectedPaymentMethod(uniquePaymentMethods?.[0] || null)
  }, [uniquePaymentMethods]);

  const tokenBalance = useBalance(selectedPaymentMethod?.network || null, account || null);

  const onClickCopyReferralLink = useCallback(() => {
    if (!account) {
      return;
    }

    getReferralLink(account)
      .then((refLink) => copyToClipboard(refLink.data.referralLink.link))
  }, [account]);

  const [selectedCurrency, setSelectedCurrency] = useState<string>(selectedPaymentMethod?.currency || '');

  const [value1, setValue1] = useState<string>('');
  const [value2, setValue2] = useState<string>('');

  const { makeTransaction, isLoading, transferError } = useTransfer();

  useEffect(() => {
    const price = getExchangeRate(selectedCurrency);
    if (typeof price !== 'number') {
      return;
    }

    const productAmount = price * Number(value1);
    setValue2(String(productAmount));
  }, [value1, getExchangeRate, selectedCurrency]);

  const onClickBuy = useCallback(async () => {
    if (!selectedPaymentMethod || !account || !selectedCurrency) {
      console.warn('onClickBuy error: bad inputs');
      return;
    }
    const price = getExchangeRate(selectedCurrency);
    if (typeof price !== 'number') {
      console.warn(`onClickBuy error: no price for selectedCurrency ${selectedCurrency}`);
      return;
    }
    const productTokensAmount = Number(value1) * price;

    const response = await getPresaleDepositAddress(
      selectedCurrency,
      selectedPaymentMethod.network,
      account,
    )

    makeTransaction(selectedPaymentMethod, productTokensAmount, response.data.depositAddress);
  }, [account, selectedPaymentMethod, value1, getExchangeRate, selectedCurrency]);

  if (!status
    || !paymentMethods
    || !uniquePaymentMethods
    || !rates
    || !selectedPaymentMethod
  ) {
    return null;
  }

  return (
    <div
      style={{
        ...styles.container,
        border: isDarkMode ? "1px solid rgba(152, 161, 192, 0.24)" : "none",
        width: isTablet ? "95%" : "90%",
      }}
    >
      <Title level={2}>
        <Text style={{
          ...styles.displayPaneTitleSymbol,
        }}>
          {status.symbol}
        </Text>
        <Text style={{
          ...styles.displayPaneTitleSymbol,
          letterSpacing: "normal"
        }}> Presale </Text>
        <Text style={{
          ...styles.displayPaneTitleLive,
          ...styles.displayPaneTitleSymbol,
          letterSpacing: "normal"
        }}>LIVE</Text>
      </Title>
      <Flex justify={'space-evenly'} align={'center'} style={styles.priceInfoWrapper}>
        <div style={styles.priceInfo}>
          <Text style={styles.priceTitle}>{`Actual Price:`}</Text>
          <span style={styles.priceInfoPrice}>${status.currentPrice}</span>
        </div>
        <div style={styles.priceInfo}>
          <Text style={styles.priceTitle}>{`Listing Price:`}</Text>
          <span style={styles.priceInfoPrice}>${status.finalPrice}</span>
        </div>
      </Flex>
      <div style={styles.progressBar}>
        <ProgressBar progress={300000} totalSquares={33}/>
      </div>

      <Flex vertical>
        <Text style={styles.priceTitle}>
          USD Raised:
          <Text style={styles.priceInfoPrice}>
            ${status.usdRaised} / ${status.goal}
          </Text>
        </Text>
        <Text style={styles.priceTitle}>
          Your
          <Text style={styles.displayPaneTitleSymbol}>
            {` ${status.symbol}`}
          </Text> balance:
          <Text style={styles.displayPaneTitleLive}>
            {tokenBalance || '...'}
          </Text>
        </Text>
      </Flex>

      <PaymentMethods
        paymentMethods={uniquePaymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
      />

      <Divider />

      <PayWith
        paymentMethods={paymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />

      {/* TODO change calculation on change network and currency */}
      <div style={styles.inputContainer}>
        <Input
          placeholder="0"
          style={styles.inputField}
          value={value1}
          onChange={(e) => {
            const value = e.target.value;
            setValue1(value);
            const productTokensAmount = Number(value) * (getExchangeRate(selectedCurrency) || 0);
            setValue2(String(productTokensAmount));
          }}
          // type='number'
          // addonBefore={<UsdtIcon /> } TODO fix icon rendering
        />
        <Input
          placeholder="0"
          style={styles.inputField}
          addonBefore={`Receive ${status.symbol}`}
          value={value2}
        />
      </div>

      {isActive && (
        <>
          <Divider />

          {transferError && (
            <Text style={styles.warningText}>{transferError}</Text>
          )}

          <Button
            type="primary"
            style={styles.actionButton}
            onClick={onClickBuy}
            disabled={isLoading}
          >
            INSTANT BUY
          </Button>

          <Text style={styles.footerText}>{
            `CONNECTED WALLET (${getEllipsisTxt(account || '', 4)})`
          }</Text>

          <br />
          <Button
            type="link"
            icon={<PlusIcon style={styles.icon} />}
            style={styles.refButton}
            onClick={onClickCopyReferralLink}
          >
            Copy referral link
          </Button>
        </>
      )}
    </div>
  );
};

export default DisplayPane;
