import { useEffect, useRef } from "react";

export const usePolling = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay) {
      return
    }

    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    tick();

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
};
